
import { defineComponent, onBeforeMount, ref, provide, computed } from 'vue'
import Product from '@/components/product/index.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from '@/http'
import { IAddProduct } from '@/interfaces'

export default defineComponent({
	name: 'PageProductUpdate',

	components: { Product },

	setup() {
		const store = useStore()
		const route = useRoute()
		const router = useRouter()

		let product = ref<IAddProduct | null>(null)

		async function getProductById() {
			try {
				product.value = (await axios.get('/product/get-by-id/' + route.params.id)).data
			} catch (e) {
				router.push({ name: 'Error' })
			}
		}

		function setProduct(valProduct: IAddProduct | undefined = undefined) {
			if (valProduct !== undefined) {
				product.value = valProduct
			}
		}

		const isUserAllowedChange = computed(() => {
			const userRoleIds = store.state.auth?.userRoleIds
			const allowedRoles = [4, 9]
			let isUserCanChange = false
			for (const userRoleId of userRoleIds) {
				if (allowedRoles.includes(userRoleId)) {
					isUserCanChange = true
				}
			}
			return isUserCanChange
		})

		provide('isUserAllowedChange', isUserAllowedChange)

		provide('product', product)

		provide('setProduct', setProduct)

		provide('updateProduct', getProductById)

		onBeforeMount(() => {
			getProductById()
		})

		function getTitle(product: any) {
			const productName = product?.name
			const type = product.type.name
			const brand = product?.brand?.name
			const colors = getColorsStr(product.colors)
			let title = `Обзор товара`
			if (productName) return `${title} ${productName}`
			if (type) title += ` ${type}`
			if (brand) title += ` ${brand}`
			if (colors) title += ` ${colors}`
			return title
		}

		function getColorsStr(arr: Array<{ names: string }> | null) {
			if (!arr) return ''

			return ' ' + arr.map(el => el.names.replace('цвета', '').replace(' ', '')).join(', ') + ' цвета'
		}

		return {
			product,
			getTitle
		}
	}
})
