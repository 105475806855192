import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "common-h1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Product = _resolveComponent("Product")!

  return (_ctx.product)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.getTitle(_ctx.product)), 1),
        _createVNode(_component_Product)
      ], 64))
    : _createCommentVNode("", true)
}